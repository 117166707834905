import React, { useState, useEffect } from "react";
import "./styles.css";

function App() {
  const [user, setUser] = useState({ avatar: "", name: "" });
  const [monBalance, setMonBalance] = useState(null);
  const [tonBalance, setTonBalance] = useState(null);
  const [goldBalance, setGoldBalance] = useState(null);
  const [stageBig, setStageBig] = useState(null);
  const [stageSmall, setStageSmall] = useState(null);
  const [levelCard, setLevelCard] = useState([]);
  const [energy, setEnergy] = useState(null);
  const [playerCard, setPlayerCard] = useState([]);
  const [offlineReward, setOfflineReward] = useState(null);
  const [progress, setProgress] = useState([]);

  const [isOfflineRewardVisible, setIsOfflineRewardVisible] = useState(false);

  const [fightCountdown, setFightCountdown] = useState(null);
  const [fightData, setFightData] = useState(null);
  const [isFighting, setIsFighting] = useState(false);
  const [countdownOverlayVisible, setCountdownOverlayVisible] = useState(false);

  const [isVictoryPopupVisible, setIsVictoryPopupVisible] = useState(false);
  const [isFailurePopupVisible, setIsFailurePopupVisible] = useState(false);

  const [isCardTransition, setIsCardTransition] = useState(false);

  const [currentView, setCurrentView] = useState('home'); // 新增 currentView 状态

  const [isFirstLogin, setIsFirstLogin] = useState(null);
  const [generateStarterPack, setGenerateStarterPack] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isMonsterOpen, setIsMonsterOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [teamOrder, setTeamOrder] = useState(null);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();

      window.Telegram.WebApp.BackButton.onClick(() => {
        window.Telegram.WebApp.close();
      });

      const initData = window.Telegram.WebApp.initDataUnsafe;
      if (initData && initData.user) {
        const { first_name, last_name, username, photo_url, id } = initData.user;
        setUser({
          name: `${first_name || ""}`,
          avatar: photo_url || "https://via.placeholder.com/50",
        });

        fetch(`https://api.tonchain.monster/api/login?telegramId=7894008985&nickname=${first_name}&avatarUrl=${photo_url}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            // data.data = JSON.parse(data.data);
            setMonBalance(data.data.mon_balance);
            setTonBalance(data.data.ton_balance);
            setGoldBalance(data.data.gold_balance);
            setStageBig(data.data.stage_big);
            setStageSmall(data.data.stage_small);
            setLevelCard(data.data.level_card);
            setEnergy(data.data.energy);
            setPlayerCard(data.data.player_card);
            setOfflineReward(data.data.offline_reward);
            setProgress(generateProgress(data.data.stage_small));
            setIsFirstLogin(data.data.starterPack);
            setIsOfflineRewardVisible(true);
            fetchCards();
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
          });
      }
    }
  }, []);

  const handleStarterClick = () => {
    setIsFirstLogin(false);
    setGenerateStarterPack(true);
  };

  const handleFinishStarter = () => {
    fetch(`https://api.tonchain.monster/api/getstarterpack?telegram_id=7894008985`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setCurrentView('home');
        setGenerateStarterPack(false);
      })
      .catch(error => {
        console.error('Error fetching fight data:', error);
      });
  };

  const handleNext = () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClaimReward = () => {
    setCurrentView('shop');
  };

  const handleVictoryClaim = () => {
    setIsVictoryPopupVisible(false);
    setIsCardTransition(true);
    fetch(`https://api.tonchain.monster/api/nextlevel?telegram_id=7894008985`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setMonBalance(data.data.mon_balance);
        setTonBalance(data.data.ton_balance);
        setGoldBalance(data.data.gold_balance);
        setStageBig(data.data.stage_big);
        setStageSmall(data.data.stage_small);
        setLevelCard(data.data.level_card);
        setPlayerCard(data.data.player_card);
        setEnergy(data.data.energy);
        setProgress(generateProgress(data.data.stage_small));
        setTimeout(() => {
          setIsCardTransition(false);
        }, 2000);
      })
      .catch(error => {
        console.error('Error fetching fight data:', error);
      });

    const enemyCards = document.querySelectorAll('.enemy_a,.enemy_b,.enemy_c');
    const playerCards = document.querySelectorAll('.monster_card');

    setTimeout(() => {
      enemyCards.forEach(card => {
        card.style.filter = 'none';
      });

      playerCards.forEach(card => {
        card.style.filter = 'none';
      });
    }, 350);
  };

  const handleFailButton = () => {
    setIsFailurePopupVisible(false);
    fetch(`https://api.tonchain.monster/api/login?telegramId=7894008985&nickname=&avatarUrl=`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setStageBig(data.data.stage_big);
        setStageSmall(data.data.stage_small);
        setLevelCard(data.data.level_card);
        setEnergy(data.data.energy);
        setPlayerCard(data.data.player_card);
        setProgress(generateProgress(data.data.stage_small));

        // 将所有 playerCard 和 enemyCard 的 saturate 样式改为 100%
        const playerCards = document.querySelectorAll('.monster_card');
        const enemyCards = document.querySelectorAll('.enemy_a, .enemy_b, .enemy_c');

        playerCards.forEach(card => {
          card.style.filter = 'saturate(100%)';
        });

        enemyCards.forEach(card => {
          card.style.filter = 'saturate(100%)';
        });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };
  const handleOfflineClaim = () => {
    setIsOfflineRewardVisible(false);
    setGoldBalance(prevGoldBalance => prevGoldBalance + offlineReward);
  };

  const handleFightClick = () => {
    setIsFighting(true);
    setCountdownOverlayVisible(true);
    setFightCountdown(3);

    setTimeout(() => {
      fetchFightData();
    }, 3000);

    const countdownInterval = setInterval(() => {
      setFightCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      setFightCountdown('FIGHT!');
      setTimeout(() => {
        setCountdownOverlayVisible(false);
      }, 1000);
    }, 3000);
  };

  const fetchFightData = () => {
    fetch(`https://api.tonchain.monster/api/fight?telegram_id=7894008985`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setFightData(data.data);
        startFightAnimation(data.data);
      })
      .catch(error => {
        console.error('Error fetching fight data:', error);
      });
  };

  const startFightAnimation = (data) => {
    let roundIndex = 0;
    let subRoundIndex = 0; // 新增子对象索引

    const fightInterval = setInterval(() => {
      if (roundIndex >= data.rounds.length) {
        clearInterval(fightInterval);
        setIsFighting(false);

        // 检查战斗结果
        if (data.win) {
          setIsVictoryPopupVisible(true); // 显示胜利popup
        } else {
          setIsFailurePopupVisible(true); // 显示失败popup
        }

        return;
      }

      const roundDataArray = data.rounds[roundIndex];
      if (subRoundIndex >= roundDataArray.length) {
        // 如果当前对象的所有子对象都处理完了，移动到下一个对象
        roundIndex++;
        subRoundIndex = 0; // 重置子对象索引
        return;
      }

      const roundData = roundDataArray[subRoundIndex];
      const { remainingHealthPoints, hurt } = roundData;

      // 获取当前对战的卡牌索引
      const cardIndex = roundIndex % 3;

      // 振动卡牌
      const enemyCard = document.querySelector(`.enemy_${String.fromCharCode(97 + cardIndex)}`);
      const playerCard = document.querySelector(`.monster_card:nth-child(${cardIndex + 1})`);

      if (enemyCard && playerCard) {
        enemyCard.classList.add('shake');
        playerCard.classList.add('shake');

        setTimeout(() => {
          enemyCard.classList.remove('shake');
          playerCard.classList.remove('shake');
        }, 500);

        // 显示掉血动画
        const showHurtAnimation = (card, hurtValue) => {
          const hurtNumber = document.createElement('div');
          hurtNumber.className = 'hurt-number';
          hurtNumber.textContent = `-${hurtValue}`;
          card.appendChild(hurtNumber);

          setTimeout(() => {
            card.removeChild(hurtNumber);
          }, 1000);
        };

        showHurtAnimation(enemyCard, hurt[`B`]);
        showHurtAnimation(playerCard, hurt[`A`]);

        // 更新卡牌的生命值
        setLevelCard(prevLevelCard => {
          const newLevelCard = [...prevLevelCard];
          newLevelCard[cardIndex].health = remainingHealthPoints[`B`];
          if (remainingHealthPoints[`B`] <= 0) {
            setTimeout(() => {
              enemyCard.style.filter = 'saturate(0)';
            }, 500);
          }
          return newLevelCard;
        });

        setPlayerCard(prevPlayerCard => {
          const newPlayerCard = [...prevPlayerCard];
          newPlayerCard[cardIndex].health = remainingHealthPoints[`A`];
          if (remainingHealthPoints[`A`] <= 0) {
            setTimeout(() => {
              playerCard.style.filter = 'saturate(0)';
            }, 500);
          }
          return newPlayerCard;
        });
      }

      subRoundIndex++; // 处理完当前子对象后，移动到下一个子对象
    }, 1000); // 每2秒进行一次战斗动画
  };
  const generateProgress = (stage) => {
    const progress = [];
    const stageSmall = stage
    for (let i = 1; i < 5; i++) {
      if (i < stageSmall) {
        progress.push(<div key={`dot-${i}`} className="small_dot_finish"></div>);
        if (i < (stageSmall - 1)) {
          progress.push(<div key={`line-${i}`} className="line_finish"></div>);
        } else {
          progress.push(<div key={`line-${i}`} className="line"></div>);
        }
      } else {
        progress.push(<div key={`dot-${i}`} className="small_dot"></div>);
        progress.push(<div key={`line-${i}`} className="line"></div>);
      }
    }
    return progress;
  };

  const handleOpenMonster = (order) => {
    setTeamOrder(order);
    fetchCards();
    setIsMonsterOpen(true);
  };

  const handleCloseMonster = () => {
    setIsMonsterOpen(false);
  };

  const fetchCards = async () => {
    try {
      const response = await fetch('https://api.tonchain.monster/api/monsters?telegram_id=7894008985');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCards(data.data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleCardClick = async (card) => {
    if (teamOrder === null) {
      alert("Order is not set.");
      return;
    }

    try {
      const response = await fetch(`https://api.tonchain.monster/api/editteam?telegram_id=7894008985&order=${teamOrder}&cardid=${card.id}`, {
        method: 'get',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json(); // 解析响应体

      if (data.data) { // 检查 data.data 是否存在
        const newPlayerCard = [...playerCard];
        newPlayerCard[teamOrder - 1] = card;
        setPlayerCard(newPlayerCard);
        setIsMonsterOpen(false);
      } else {
        alert("Failed to select the card.");
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      alert("An error occurred while selecting the card.");
    }
  };

  const handleEditClick = () => {
    setPlayerCard([null, null, null])
  };

  return (
    <div className="app">
      <div className="header">
        <div className="userInfo">
          <div className="avatar">
            <img src={user.avatar} alt="User Avatar" />
          </div>
          <div className="nameBalance">
            <div className="nickName">{user.name}</div>
            <div className="gtBalance">
              <div className="goldBalance">
                <img src="https://www.tonchain.monster/image/gold.png" /> {goldBalance}
              </div>
              <div className="tonBalance">
                <img src="https://www.tonchain.monster/image/ton.png" /> {tonBalance}
              </div>
            </div>
          </div>
        </div>
        <div className="monBalance"> 
          <text>$MON</text>
          <div className="monBalanceNumber">{monBalance}</div>
        </div>
      </div>

      {currentView === 'home' && ( // 根据 currentView 控制显示内容
        <>
          {isFirstLogin && (
            <div id="tutorialOverlay" className="tutorial-overlay" key={currentPage}>
              <div id="tutorialContent" className="tutorial-content">
                <div id="page1" className={`tutorial-page ${currentPage === 1 ? 'active' : ''}`}>
                  <div className="three-steps">
                    <div className="whitestep"></div>
                    <div className="greystep"></div>
                    <div className="greystep"></div>
                  </div>
                  <h2>欢迎来到游戏</h2>
                  <p>这里是游戏的第一步介绍。</p>
                </div>
                <div id="page2" className={`tutorial-page ${currentPage === 2 ? 'active' : ''}`}>
                  <div className="three-steps">
                    <div className="greystep"></div>
                    <div className="whitestep"></div>
                    <div className="greystep"></div>
                  </div>
                  <h2>第二步</h2>
                  <p>这里是游戏的第二步介绍。</p>
                </div>
                <div id="page3" className={`tutorial-page ${currentPage === 3 ? 'active' : ''}`}>
                  <div className="three-steps">
                    <div className="greystep"></div>
                    <div className="greystep"></div>
                    <div className="whitestep"></div>
                  </div>
                  <h2>第三步</h2>
                  <p>这里是游戏的第三步介绍。</p>
                </div>
                <div className="tutorial-buttons">
                  {currentPage > 1 && (
                    <button id="prevButton" className="tutorial-button" onClick={handlePrev}>
                      上一步
                    </button>
                  )}
                  {currentPage < 3 ? (
                    <button id="nextButton" className="tutorial-button" onClick={handleNext}>
                      下一步
                    </button>
                  ) : (
                    <button id="claimReward" className="starter-button" onClick={handleClaimReward}>
                      领取新手包
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="level">Level {stageBig} - {stageSmall}</div>
          <div className="progress">
            {progress}
            <div className="big_dot"></div>
          </div>
          <div className="content">
            {countdownOverlayVisible && (
              <div className="fight-countdown-overlay">
                {fightCountdown !== null ? fightCountdown : 'Fight'}
              </div>
            )}
            <div className="enemy_cards">
              {levelCard.map((enemy, index) => (
                <div key={index} className={`enemy_${String.fromCharCode(97 + index)} ${isCardTransition ? 'card-transition' : ''}`}>
                  <img src={enemy.image} />
                  <div className="number-overlay">
                    <div className="attackValue">{enemy.attack}</div>
                    <div className="healthValue">{enemy.health}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="console_title">
            <div className="console_logo">
              <div className="console_icon"></div>
              <div className="console_text">MY TEAM</div>
            </div>
            <div className="console_button">
              <div className="console_badge">TUTORIAL</div>
              <div className="console_edit" onClick={handleEditClick} >EDIT</div>
            </div>
          </div>

          <div className="console">
            <div className="utils">
              <div className="energy">
                <div className="energy_title">ENERGY</div>
                <div className="energy_bar">
                  <img src="https://www.tonchain.monster/image/flash.png" />
                  <div className="energy_progress">
                    <div className="energy_progress_bar" style={{ width: `${energy}%` }}></div>
                  </div>
                </div>
                <div className="energy_number">
                  <div className="energy_value">{energy}/100</div>
                  <div className="energy_countdown">0:59</div>
                </div>
              </div>
              <div className="arenas">
                <div className="gold_button">
                  <div className="button_upper">GOLD</div>
                  <div className="button_lower">PVP</div>
                </div>
                <div className="ton_button">
                  <div className="button_upper">TON</div>
                  <div className="button_lower">PVP</div>
                </div>
              </div>
            </div>
            <div className="fight">
              <div className="monster">

                <>
                  {playerCard.map((card, index) => (
                    <div
                      key={index}
                      className="monster_card"
                      onClick={() => card ? handleCardClick(card) : handleOpenMonster(index + 1)}
                    >
                      {card ? (
                        <>
                          <img src={card.image} alt={`Monster ${index + 1}`} />
                          <div className="number-overlay">
                            <div className="attackValue">{card.attack}</div>
                            <div className="healthValue">{card.health}</div>
                          </div>
                        </>
                      ) : (
                        <div className="add-monster">+</div>
                      )}
                    </div>
                  ))}
                </>

              </div>
              <div className="fight_button" onClick={handleFightClick}>
                FIGHT
              </div>
            </div>
          </div>

          {isOfflineRewardVisible && !isFirstLogin && offlineReward > 0 && playerCard[0] && playerCard[1] && playerCard[2] && (
            <div className="offline-reward-overlay">
              <div className="offline-reward-popup">
                <div className="offline-team">
                  <img className="offline-team-a" src={playerCard[0].image} />
                  <img className="offline-team-b" src={playerCard[1].image} />
                  <img className="offline-team-c" src={playerCard[2].image} />
                </div>
                <h4>Offline Reward</h4>
                <text>Your team has fought 20 rounds while you were offline.</text>
                <div className="offline-number">
                  <img className="offline-reward-gold" src="https://www.tonchain.monster/image/gold.png" /> {offlineReward}
                </div>
                <div className="offline-reward-button" onClick={handleOfflineClaim}>
                  Claim
                </div>
              </div>
            </div>
          )}

          {isVictoryPopupVisible && playerCard[0] && playerCard[1] && playerCard[2] && (
            <div className="victory-popup-overlay">
              <div className="victory-popup">
                <div className="offline-team">
                  <img className="offline-team-a" src={playerCard[0].image} />
                  <img className="offline-team-b" src={playerCard[1].image} />
                  <img className="offline-team-c" src={playerCard[2].image} />
                </div>
                <h4>Victory</h4>
                <div className="offline-number">
                  <img className="offline-reward-gold" src="https://www.tonchain.monster/image/gold.png" /> {offlineReward}
                </div>
                <div className="victory-popup-button" onClick={handleVictoryClaim}>
                  Claim
                </div>
              </div>
            </div>
          )}

          {isFailurePopupVisible && playerCard[0] && playerCard[1] && playerCard[2] && (
            <div className="failure-popup-overlay">
              <div className="failure-popup">
                <div className="offline-team">
                  <img className="offline-team-a" src={playerCard[0].image} />
                  <img className="offline-team-b" src={playerCard[1].image} />
                  <img className="offline-team-c" src={playerCard[2].image} />
                </div>
                <h4>Try Again</h4>
                <div className="offline-number">
                  <div className="failtext">
                    <text>How to win:</text>
                    <text>1. Strengthen Your Team By More Powerful Monsters.</text>
                    <text>2. Rearrange Your Team Order to Perform Better.</text>
                    <text>3. Get More Powerful Monsters By Loot Boxes.</text>
                  </div>
                </div>
                <div className="failure-popup-button" onClick={handleFailButton}>
                  RETRY
                </div>
              </div>
            </div>
          )}

          {isMonsterOpen && (
            <div className="victory-popup-overlay" onClick={handleCloseMonster}>
              <div className="victory-popup">
                <div className="popup-title">| MY MONSTERS</div>
                <div className="card-list">
                  {cards.map((card, index) => {
                    const isCardInPlayerCards = playerCard.some(playerCard => playerCard && playerCard.id === card.id);
                    return (
                      isCardInPlayerCards ? (
                        <div key={index} className="card-item">
                          <img src={card.image} alt={`card-${index}`} />
                          <div className="number-overlay">
                            <div className="attackValue">{card.attack}</div>
                            <div className="healthValue">{card.health}</div>
                          </div>
                          <div className="fighting-overlay">Fighting</div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="card-item"
                          onClick={() => handleCardClick(card)}
                        >
                          <img src={card.image} alt={`card-${index}`} />
                          <div className="number-overlay">
                            <div className="attackValue">{card.attack}</div>
                            <div className="healthValue">{card.health}</div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {currentView === 'shop' && (
        <>
          {isFirstLogin && (
            <div className="shopOverlay">
              <div className="overlayShelf">
                <div className="overlay-starter-pack">
                  <div className="starter-pack-title">Starter Pack</div>
                  <text>Claim Your 3 free monsters to start the game</text>
                  <div className="free-button" onClick={handleStarterClick}>GET FREE</div>
                </div>
              </div>
            </div>
          )}
          <div className="shopShelf">
            <div className="starter-pack">
              <div className="starter-pack-title">Starter Pack</div>
              <text>Claim Your 3 free monsters to start the game</text>
              <div className="free-claimed">CLAIMED</div>
            </div>
            <div className="gatcha">
              <div className="starter-pack-title">Featured Monsters</div>
              <text>Powerful Limit Edition Monsters</text>
              <div className="free-button">$5</div>
            </div>
            <div className="supply-pack">
              <div className="starter-pack-title">Energy Pack</div>
              <text>Fullfill Your Energy Gauge Now at only $1</text>
              <div className="free-button">$1</div>
            </div>
          </div>

          {generateStarterPack && (
            <div className="victory-popup-overlay">
              <div className="victory-popup">
                <div className="offline-team">
                  <img className="offline-team-a" src="https://www.tonchain.monster/card/seally.png" />
                  <img className="offline-team-b" src="https://www.tonchain.monster/card/blues.png" />
                  <img className="offline-team-c" src="https://www.tonchain.monster/card/seally.png" />
                </div>
                <h4>Congratulations!!!</h4>
                <div className="starter-detail">
                  You got your first 3 monsters!
                </div>
                <div className="starter-detail">
                  Line up them in your team NOW!
                </div>
                <div className="victory-popup-button" onClick={handleFinishStarter}>
                  Got it!
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {currentView === 'monster' && (
        <>
          <div className="monster-content">
            <div className="card-list">
              {cards.map((card, index) => (
                <div key={index} className="card-item">
                  <img src={card.image} />
                  <div className="number-overlay">
                    <div className="attackValue">{card.attack}</div>
                    <div className="healthValue">{card.health}</div>
                  </div>
                  {card.enterGame && (
                    <div className="fighting-overlay">Fighting</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <div className="footer">
        <div className={currentView === 'home' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('home')}>
          <img src="https://www.tonchain.monster/image/arena_a.png" alt="Arena" /> ARENA
        </div>
        <div className={currentView === 'monster' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('monster')}>
          <img src="https://www.tonchain.monster/image/monster_a.png" alt="Monster" /> MONSTER
        </div>
        <div className={currentView === 'earn' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('earn')}>
          <img src="https://www.tonchain.monster/image/earn_a.png" alt="Earn" /> EARN
        </div>
        <div className={currentView === 'top' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('top')}>
          <img src="https://www.tonchain.monster/image/top_a.png" alt="Top" /> TOP
        </div>
        <div className={currentView === 'friends' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('friends')}>
          <img src="https://www.tonchain.monster/image/friends_a.png" alt="Friends" /> FRIENDS
        </div>
        <div className={currentView === 'shop' ? 'navi-active' : 'navi'} onClick={() => setCurrentView('shop')}>
          <img src="https://www.tonchain.monster/image/shop_a.png" alt="Shop" /> SHOP
        </div>
      </div>
    </div>
  );
}

export default App;